(function () {
     $('#highscore').DataTable({
         bFilter: false,
         bInfo: false,
         bLengthChange: false,
         ordering: false,
         responsive:true,
         responsive: {
            details: false
        }
     });

     function paginateScroll() {
         document.getElementById("highscore").scrollIntoView(true);
         $('.paginate_button').unbind('click', paginateScroll);
         $('.paginate_button').bind('click', paginateScroll);
     }
     if ($("section").hasClass("section-highscore")) {
          $('.paginate_button').click(function(){
               paginateScroll();
          });
     }



     $('.btn.fb[href~="#"]').click(function (e) {
        var url = $(this).data('href');

        var shareUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + url;

        sharer(shareUrl);
        e.preventDefault();
     });

     $('.btn.twt[href~="#"]').click(function (e) {
        var url = $(this).data('href');
        var text = $(this).data('text');

        var shareUrl = 'https://twitter.com/intent/tweet?text='+ text + '&url=' + url;

        sharer(shareUrl);
        e.preventDefault();
     });

     function sharer(url) {
        window.open(url, '_blank', 'width=600,height=460');
     }

     $( "#slider-burn,#slider-add" ).slider({
          min: 200,
          max: 800,
          step:200,
          value: 400,
          range: "min",
          slide: function( event, ui ) {
              $( "#slider-burn-value,#slider-add-value" ).val(ui.value );
          },
          change: function(event, ui) {
              $('#slider-burn-value,#slider-add-value').attr('value', ui.value);
          }
        });
        $( "#slider-burn-value,#slider-add-value" ).val( $( "#slider-burn,#slider-add" ).slider( "value" ) );

})();
