(function () {


    $('.calories-container .calorie').click(function(e){
        $('.calories-container .calorie').each(function (index, value){
            $(this).removeClass('active');
            $(this).find('.image img').attr("src", "public/img/icon-calories.png");
            $("#calorie_id").val("");
        });

        if (!$(this).hasClass("active")) {
            $(this).find('.image img').attr("src", "public/img/icon-calories-chosen.png");
            $(this).addClass('active');
            $("#calorie_id").val($(this).data("id"));
        }

    });

    $('.calories-container .tread').click(function(e){
        $('.calories-container .tread').each(function (index, value){
            if($(this).hasClass("active")) {
                var _image = $(this).find(".swapImage");
                var current = _image.attr("src");
                var swap = _image.attr("data-swap");
                _image.attr('src', swap).attr("data-swap",current);
            }
            $("#tread_id").val("");
            $(this).removeClass('active');
        });

        if (!$(this).hasClass("active")) {
            $(this).addClass('active');
            $("#tread_id").val($(this).data("id"));

            var _image = $(this).find(".swapImage");
            var current = _image.attr("src");
            var swap = _image.attr("data-swap");
            _image.attr('src', swap).attr("data-swap",current);
        }

    });

    if ($('#calorie_id').val()!==""){
        var id = $('#calorie_id').val();
        var _this = $('.calories-container [data-id=' + id + ']');
        _this.addClass("active");
        _this.find('.image img').attr("src", "public/img/icon-calories-chosen.png");
    }

    $('#highscore ').on('click','.user',function(){
        $('#highscore .user').each(function (index, value){
            $(this).removeClass('active');
            $("#user_id").val("");
        });
        $("#user_id").val($(this).data("id"));
        $(this).addClass('active');
    });
})();
