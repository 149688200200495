(function () {

        var max_fields      = 10; //maximum input boxes allowed
        var wrapper         = $(".formRefWrapper"); //Fields wrapper
        var add_button      = $(".add_field_button"); //Add button ID

        var x = 3; //initlal text box count
        $(add_button).click(function(e){ //on add input button click
            e.preventDefault();
            if(x < max_fields){ //max input box allowed
                x++; //text box increment
                $(wrapper).append('<label for="">E-Mail Freund ' + x + '</label><input type="email" class="input" name="email[]" placeholder="user' + x + '@example.de"/>'); //add input box
            }
        });

})();
