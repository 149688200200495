(function () {


    $('.activity-container .activity').click(function(e){ //on add input button click
        $('.activity-container .activity').each(function (index, value){
            $(this).removeClass('active');
            $(this).find('.image img').attr("src", "public/img/icon-activity.png");
            $("#activity_id").val("");
        });

        if (!$(this).hasClass("active")) {
            $(this).find('.image img').attr("src", "public/img/icon-activity-chosen.png");
            $(this).addClass('active');
            $("#activity_id").val($(this).data("id"));
        }
    });

    if ($('#activity_id').val()!==""){
        var id = $('#activity_id').val();
        var _this = $('.activity-container [data-id=' + id + ']');
        _this.addClass("active");
        _this.find('.image img').attr("src", "public/img/icon-activity-chosen.png");
    }

})();
